/** @jsx jsx */
import { jsx, Styled } from 'theme-ui';
import PropTypes from 'prop-types';
import { FaExternalLinkAlt } from 'react-icons/fa';
import MarkdownRender from './markdown-render';
import UnbulletedList from './unbulleted-list';

export const PublicationItem = ({
  title, publicationUrl, publicationAuthors, body,
}) => (
  <li sx={{ mb: 4, pb: 4 }}>
    {body && <MarkdownRender md={body} />}
    <Styled.a
      href={publicationUrl}
      sx={{
        textDecoration: 'none',
        border: 'solid 1px #666',
        borderRadius: '5px',
        display: 'inline-block',
        py: 1,
        pl: 2,
        pr: 5,
        position: 'relative',
        fontSize: 0,
        ':hover': {
          bg: 'bgmuted',
          borderColor: 'bgmuted',
        },
      }}
    >
      <strong>{title}</strong>
      <ul
        sx={{
          listStyleType: 'none',
          p: 0,
          '::before': {
            content: '"By "',
          },
          li: {
            display: 'inline',
            '& + li:before': {
              content: '", "',
            },
          },
        }}
      >
        {publicationAuthors.map(({ name }) => <li key={name}>{name}</li>)}
      </ul>
      <FaExternalLinkAlt
        size="1.75em"
        sx={{
          position: 'absolute',
          right: '10px',
          top: '10px',
        }}
      />
    </Styled.a>
  </li>
);

PublicationItem.propTypes = {
  title: PropTypes.string.isRequired,
  publicationUrl: PropTypes.string.isRequired,
  publicationAuthors: PropTypes.arrayOf(PropTypes.object),
  body: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
};

PublicationItem.defaultProps = {
  publicationAuthors: [],
  body: null,
};

const ExternalPublicationList = ({ links }) => (
  <UnbulletedList
    sx={{
      'li:not(:last-child)': {
        borderBottom: 'dotted 1px #000',
      },
    }}
  >
    {links.map(({ node: { frontmatter, body } }) => (
      <PublicationItem
        key={frontmatter.title}
        title={frontmatter.title}
        publicationUrl={frontmatter.publication_url}
        publicationAuthors={frontmatter.publication_authors}
        body={body}
      />
    ))}
  </UnbulletedList>
);

ExternalPublicationList.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        frontmatter: PropTypes.shape({
          title: PropTypes.string.isRequired,
          publication_url: PropTypes.string.isRequired,
          publication_authors: PropTypes.arrayOf(PropTypes.object),
        }).isRequired,
        body: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.object,
        ]),
      }).isRequired,
    }).isRequired,
  ).isRequired,
};

export default ExternalPublicationList;
