/* eslint-disable react/jsx-props-no-spreading */
/** @jsx jsx */
import { jsx } from 'theme-ui';

const FullWidthGridCol = (props) => (
  <div
    {...props}
    sx={{
      gridColumn: [
        'content-start/content-end',
        '2/-2',
        '3/-3',
      ],
    }}
  />
);

export default FullWidthGridCol;
