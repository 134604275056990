import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
/** @jsx jsx */
import { jsx, Styled } from 'theme-ui';
import Img from '../components/image';
import Layout from '../components/layout';
import FullWidthGridCol from '../components/full-width-grid-col';
import ExternalPublicationList from '../components/pub-list';
import UnbulletedList from '../components/unbulleted-list';
import Metadata from '../components/metadata';

const InsightsList = ({ insights }) => (
  <UnbulletedList
    sx={{
      display: [null, null, 'grid'],
      gridTemplateColumns: '1fr 1fr',
      gridColumnGap: 4,
    }}
  >
    {insights.map(({ node: { fields, frontmatter, excerpt } }) => (
      <li sx={{ mb: 4 }} key={frontmatter.title}>
        <Styled.a
          href={fields.slug}
          sx={{
            textDecoration: 'none',
            display: [null, 'grid', 'block'],
            gridTemplateColumns: '1fr 2fr',
            gridColumnGap: 4,
            ':hover p': {
              color: 'primary',
            },
          }}
        >
          <div
            sx={{
              gridColumnGap: 1,
              gridRow: '1 / 3',
            }}
          >
            <Img
              src={frontmatter.image}
              alt={frontmatter.title}
            />
          </div>
          <Styled.h2 sx={{ gridColumn: 2, mt: [2, 0, 2] }}>{frontmatter.title}</Styled.h2>
          <Styled.p sx={{ gridColumn: 2 }}>{excerpt}</Styled.p>
        </Styled.a>
      </li>
    ))}
  </UnbulletedList>
);

InsightsList.propTypes = {
  insights: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        fields: PropTypes.shape({
          slug: PropTypes.string.isRequired,
        }).isRequired,
        frontmatter: PropTypes.shape({
          title: PropTypes.string.isRequired,
          image: PropTypes.object.isRequired,
        }).isRequired,
        excerpt: PropTypes.string,
      }).isRequired,
    }).isRequired,
  ).isRequired,
};

export const PerspectivesTemplate = ({
  insightsTitle, articlesTitle, booksTitle, articlesBooksIntro,
  insights, articles, books,
}) => (
  <div
    sx={{
      display: 'grid',
      gridTemplateRows: '[header] auto [content] auto [footer] auto',
      gridTemplateColumns: [
        '1em [content-start] repeat(3, 1fr) [halfway] repeat(3, 1fr) [content-end] 1em',
        '1em 1fr [content-start] repeat(2, 1fr) [halfway] repeat(2, 1fr) [content-end] 1fr 1em',
        '1em repeat(3, 1fr) [content-start] repeat(3, 1fr) [halfway] repeat(3, 1fr) [content-end] repeat(3, 1fr) 1em',
      ],
      gridColumnGap: [null, 3],
    }}
  >
    <FullWidthGridCol>
      <Styled.h1>{insightsTitle}</Styled.h1>
      {insights && <InsightsList insights={insights} />}
    </FullWidthGridCol>
    <FullWidthGridCol
      sx={{
        py: [3, 4],
        mb: 4,
        borderTop: 'solid 1px #666',
        borderBottom: 'solid 1px #666',
      }}
    >
      {articlesBooksIntro}
    </FullWidthGridCol>
    <div
      sx={{
        gridColumn: [
          'content-start/content-end',
          '2/span 3',
          '3/span 4',
        ],
      }}
    >
      <Styled.h1>{articlesTitle}</Styled.h1>
      {articles && <ExternalPublicationList links={articles} />}
    </div>
    <div
      sx={{
        gridColumn: [
          'content-start/content-end',
          'halfway/span 3',
          'halfway/span 4',
        ],
      }}
    >
      <Styled.h1>{booksTitle}</Styled.h1>
      {books && <ExternalPublicationList links={books} />}
    </div>
  </div>
);

PerspectivesTemplate.propTypes = {
  insightsTitle: PropTypes.string.isRequired,
  articlesTitle: PropTypes.string.isRequired,
  booksTitle: PropTypes.string.isRequired,
  articlesBooksIntro: PropTypes.string.isRequired,
  insights: PropTypes.arrayOf(PropTypes.object),
  articles: PropTypes.arrayOf(PropTypes.object),
  books: PropTypes.arrayOf(PropTypes.object),
};

PerspectivesTemplate.defaultProps = {
  insights: null,
  articles: null,
  books: null,
};

const PerspectivesPage = ({ data }) => {
  const {
    mdx: page,
    insights,
    articles,
    books,
  } = data;
  const { frontmatter: metadata } = page;

  return (
    <Layout>
      <Metadata pageData={metadata} />
      <PerspectivesTemplate
        insightsTitle={metadata.insightsTitle}
        articlesTitle={metadata.articlesTitle}
        booksTitle={metadata.booksTitle}
        articlesBooksIntro={metadata.articlesBooksIntro}
        insights={insights.edges}
        articles={articles.edges}
        books={books.edges}
      />
    </Layout>
  );
};

PerspectivesPage.propTypes = {
  data: PropTypes.shape({
    mdx: PropTypes.object,
    insights: PropTypes.shape({
      edges: PropTypes.array,
    }),
    articles: PropTypes.shape({
      edges: PropTypes.array,
    }),
    books: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }).isRequired,
};

export default PerspectivesPage;

export const pageQuery = graphql`
  query($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        title
        meta_description
        articlesBooksIntro
        insightsTitle
        articlesTitle
        booksTitle
      }
    }
    insights: allMdx(
      filter: {frontmatter: {templateKey: {eq: "insight"}}},
      sort: {fields: frontmatter___date, order: DESC}
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            image {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          excerpt(pruneLength: 200)
        }
      }
    }
    articles: allMdx(
      filter: {fields: {slug: {glob: "/articles/*"}}},
      sort: {fields: frontmatter___date, order: DESC}
    ) {
      edges {
        node {
          frontmatter {
            title
            publication_url
            publication_authors {
              name
            }
          }
          body
        }
      }
    }
    books: allMdx(
      filter: {fields: {slug: {glob: "/books/*"}}},
      sort: {fields: frontmatter___date, order: DESC}
    ) {
      edges {
        node {
          frontmatter {
            title
            publication_url
            publication_authors {
              name
            }
          }
          body
        }
      }
    }
  }
`;
